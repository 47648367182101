<div class="body">
  <!-- Parallax Pixel Background Animation -->
  <section class="animated-background">
    <div id="stars1"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
  </section>
  <!-- End of Parallax Pixel Background Animation -->

  <a id="profilePicture" href="https://www.juanmoraza.com/">
    <img src="/assets/links/juan-looking-left.jfif" alt="Profile Picture">
  </a>

  <div class="overlay" id="popup">
    <div class="popup">
      <div class="popup-photo">
        <a href="/assets/links/juan-looking-left.jfif" target="_blank">
          <img src="/assets/links/juan-looking-left.jfif" alt="Profile Picture">
        </a>
      </div>
      <div class="popup-quote">
        But... the sensation that I've lost something lingers for a longtime after I wake up.
      </div>
      <a class="popup-close" onclick="history.back()">&times;</a>
    </div>
  </div>

  <div id="userName">
    @morazaone
  </div>

  <div id="links">
        <a class="link" href="https://youtu.be/zDSYqCndvXc" target="_blank">
      <i class="fab fa-youtube">&nbsp;</i>Most recent video
    </a>
    <a class="link" href="https://www.juanmoraza.com/" target="_blank">
      <i class="fas fa-globe">&nbsp;</i>Juan's site
    </a>
    <a class="link" href="https://www.youtube.com/channel/UCDZBIyZK_BFT-DDiExjkoRw" target="_blank">
      <i class="fab fa-youtube">&nbsp;</i>Youtube
    </a>
    <a class="link" href="https://www.instagram.com/morazaone" target="_blank">
      <i class="fab fa-instagram">&nbsp;</i>Instagram
    </a>
    <a class="link" href="https://www.linkedin.com/in/morazajuan" target="_blank">
      <i class="fab fa-linkedin">&nbsp;</i>Linkedin
    </a>
    <a class="link" href="https://www.facebook.com/morazaone" target="_blank">
      <i class="fab fa-facebook">&nbsp;</i>Facebook
    </a>
    <a class="link" href="https://github.com/morazaone" target="_blank">
      <i class="fab fa-github">&nbsp;</i>Github
    </a>
    <a class="link" href="https://twitter.com/morazaone" target="_blank">
      <i class="fab fa-twitter">&nbsp;</i>Twitter
    </a>

  </div>

  <div id="hashtag">
    #SeFeliz
  </div>

</div>
