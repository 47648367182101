<div class="container">
  <div class=""  >
  <div class="row">
    <div class="col-sm-8">

      <div class="row">
        <div class="col">
          <h1>Juan Moraza</h1>

        </div>
        <div class="col">
          <a class="btn resume" href="../../assets/resume-files/Juan_Moraza_Resume.pdf"
             download="Juan_Moraza_Resume.pdf">
            <i class="fa fa-download"></i> Resume
          </a>
        </div>



      </div>
<!--      <button class="btn"><i class="fa fa-download"></i> Resume</button>-->


      <div class="color-block  z-depth-1 py-1   white-text mb-2 resume-section  ">
        <h2 class="ml-4 pt-2">
          <strong >Education</strong>
        </h2>
      </div>
      <div *ngFor="let education of resume.education">
        <div>
          <span class="h2-responsive">{{education.school}}, </span>
          <span [style]="{'font-weight':'500'}"> {{education.school_location}} </span>
        </div>
        <div>
          <span class="h5-responsive">{{education.degree}}, </span>
          <span [style]="{'font-weight':'500'}"> {{education.graduation_date}} </span>
        </div>

      </div>
      <!-- Languages Skills        -->
      <div class="color-block  z-depth-1 py-1   white-text mb-2 resume-section  ">
        <h2 class="ml-4 pt-2">
          <strong >
            Languages Skills
          </strong>
        </h2>
      </div>
      <div>
        <p >
          {{this.skills}}
        </p>
      </div>
      <!----------------- Experience        ------------------>
      <div class="color-block  z-depth-1 py-1   white-text mb-2 resume-section  ">
        <h2 class="ml-4 pt-2"
        ><strong >
          Experience
        </strong>
        </h2>
      </div>
      <div *ngFor="let job of resume.experience">
        <!--Position Name          -->
        <p class="h4-responsive mb-0" [style]="{'text-transform':'uppercase'}">
          <strong>
            {{job.job_name}}
          </strong>
        </p>
        <!--Role          -->
        <div [style]="{'display':'inline-block', 'width':'100%','margin':'0 10px 0 0'}">
          <p class="h5-responsive role">
            <i>
              {{job.role}}
            </i>
          </p>
          <p  class="date">
            {{job.date}}
          </p>
        </div>
        <ul >
          <li *ngFor="let bullet of job.duties">
            {{bullet}}
          </li>
        </ul>
        <hr>
      </div>
      <!----------------- LEADERSHIP AND PROJECTS--->
      <div class="color-block  z-depth-1 py-1   white-text mb-2 resume-section  ">
        <h2 class="ml-4 pt-2">
          <strong >
            Leadership & Projects
          </strong>
        </h2>
      </div>
      <div *ngFor="let opportunity of resume.other_opportunities">
        <!--Position Name          -->
        <p class="h4-responsive mb-0" [style]="{'text-transform':'uppercase'}">
          <strong>
            {{opportunity.opportunity_name}}
          </strong>
        </p>
        <!--Role          -->
        <div [style]="{'display':'inline-block', 'width':'100%','margin':'0 10px 0 0'}">
          <p class="h5-responsive role">
            <i>
              {{opportunity.role}}
            </i>
          </p>
          <p  class="date">
            {{opportunity.date}}
          </p>
        </div>
        <ul >
          <li *ngFor="let bullet of opportunity.duties">
            {{bullet}}
          </li>
        </ul>
        <hr>
      </div>
      <div class="color-block  z-depth-1 py-1   white-text mb-2 resume-section  ">
        <h2 class="ml-4 pt-2">
          <strong >
            Additional
          </strong>
        </h2>
      </div>
      <span class="h4-responsive mb-0" [style]="{'text-transform':'uppercase'}">
          <strong>
            {{this.resume.additional.name}}:
          </strong>
        </span>
      <span>
          {{this.resume.additional.description}}
        </span>

    </div>
    <div class="col-sm-4" >
      <p class="h2-responsive">Skills</p>
      <div [style]="{'width':'100%'}" *ngFor="let skill of resume.skills">
        <p class="h3-responsive"> {{skill.name}}</p>
        <div class="progress md-progress " style="height: 20px">
          <div class="progress-bar"
               role="progressbar"
               style="height: 20px"
               [ngStyle]="{'width': returnPercent(skill.percent),'background':'#42a5f5' }"
               aria-valuenow="25"
               aria-valuemin="0"
               aria-valuemax="100">

            {{skill.percent}}%
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <a routerLink="/" class="btn primary-color">
    Go to main site
  </a>

</div>

