<h1>Affiliate Links works!</h1>
<h2>Amazon links</h2>

<a href="https://amzn.to/3yeSJUd" target="_blank">Derby Professional Single Edge Razor Blades, 100 Count (Pack of 2)
</a>

<a href="https://amzn.to/3RjJ9am" target="_blank">Gym Straps  </a>

<a href="https://amzn.to/3apUuF7" target="_blank">LED head lamp  </a>

<a href="https://amzn.to/3GElbBK" target="_blank">Tower Computer Case</a>

<a href=" https://amzn.to/3NNyjqn" target="_blank"> - motherboard</a>

<a href=" https://amzn.to/2PKN2Hu" target="_blank"> - processor</a>

<a href=" https://amzn.to/3zcGt7B" target="_blank"> - RAM </a>

<a href="https://amzn.to/3m4ssRF" target="_blank"> - M.2</a>

<a href=" https://amzn.to/3ztgpFN" target="_blank"> - Graphics Card</a>

 <a href="https://amzn.to/3M8YsyW" target="_blank"> - wifi/bluetooth card</a>

<a href="https://amzn.to/3x5YQd9" target="_blank"> - power supply Mostly accessories for your setup </a>

<a href="https://amzn.to/3NdfHjF" target="_blank"> - Monitor </a>

<a href="https://amzn.to/3GGWwMQ" target="_blank"> - Headset </a>

<a href="https://amzn.to/3xmy4wb" target="_blank"> - SSD</a>

<a href="https://amzn.to/3akJBUR" target="_blank"> - secondary SSD </a>

<a href="https://amzn.to/3GHxwVB" target="_blank"> - mouse  </a>

<a href="https://amzn.to/3t7DB8w" target="_blank"> - Keyboard </a>

<a href="https://amzn.to/3MohfXb" target="_blank"> - mouse pad </a>

<a href="https://amzn.to/3MaRM3l" target="_blank"> - HDD  </a>
