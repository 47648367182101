import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortfolioComponent} from './portfolio/portfolio.component'
import { HomeComponent } from './home/home.component';
import { AboutMeComponent } from "./about-me/about-me.component";
import { ListToURLComponent} from "./portfolio/list-to-url/list-to-url.component";
import {ResumeComponent} from "./resume/resume.component";
import {LinksComponent} from "./links/links.component";
import {AffiliateLinksComponent} from "./affiliate-links/affiliate-links.component";
import { RedirectGuard} from "./service/redirectGuard";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'portfolio', component: PortfolioComponent},
  { path: 'portfolio-list-to-url', component: ListToURLComponent },
  { path: 'about-me', component: AboutMeComponent },
  { path: 'resume', component: ResumeComponent },
  // { path: 'links', component: LinksComponent },
  {
    path: 'links',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://moraza.one/'
    }
  },
  { path: 'affiliate-links', component: AffiliateLinksComponent },
   {
    path: 'blog',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://blog.moraza.one/'
    }
  },
  { path: 'affiliate-links', component: AffiliateLinksComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ PortfolioComponent,HomeComponent,AboutMeComponent,ListToURLComponent,ResumeComponent, LinksComponent ];
