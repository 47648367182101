
<!--Content BELOW-->
<!-- Main navigation -->
<header>
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark" style="background:#4b515d!important; "
              mdbStickyHeader>
    <mdb-navbar-brand>
      <a class="navbar-brand" href="/">
        <img src="assets/logo.png" style="width:40px; height:40px;">
      </a>


      <a routerLink="/" [style]="{'color':'#fff'}"  ><mdb-icon fas icon="angle-left"></mdb-icon> Back</a>
    </mdb-navbar-brand>
    <!-- Links -->
    <ul class="navbar-nav ml-auto" [style]="{'flex-direction': 'row'}">
      <li class="nav-item">
        <a class="nav-link" target="_blank" target="_blank" href="https://www.linkedin.com/in/morazajuan/">
          <mdb-icon fab icon="linkedin"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="http://github.com/morazajuan">
          <mdb-icon fab icon="github"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="https://www.facebook.com/morazaone/">
          <mdb-icon fab icon="facebook"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="https://www.instagram.com/morazaone/">
          <mdb-icon fab icon="instagram"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="https://www.youtube.com/c/JuanMoraza">
          <mdb-icon fab icon="youtube"></mdb-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" target="_blank" href="https://twitter.com/morazaone/">
          <mdb-icon fab icon="twitter"></mdb-icon>
        </a>
      </li>
    </ul>
    <!-- Links -->
  </mdb-navbar>

  <!-- Full Page Intro -->
  <div class="view" style="background-image: url('./assets/chicago.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <!-- Mask & flexbox options-->
    <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
      <!-- Content -->
      <div class="container">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-md-6 white-text text-center text-md-left mt-xl-5 mb-5 wow fadeInLeft" data-wow-delay="0.3s">
            <h1 class="h1-responsive font-weight-bold mt-sm-5">About Juan </h1>
            <hr class="hr-light">
            <h6 class="mb-4">First of all, I appricate that you are taking the time to learn more about me.</h6>
            <h6 class="mb-4">I like to put things together and sharing it with the world. Feel free to check my Youtube channel by clicking the icon below!</h6>
        <a class="nav-link" target="_blank" href="https://www.youtube.com/c/JuanMoraza">
          <mdb-icon fab icon="youtube"></mdb-icon>
        </a>

<!--             <h6 class="mb-4">I like creating, building, and developing ideas and see them alive.</h6> -->
<!--             <h6 class="mb-4">Honesty, respect, understanding and hard work are core values that have shaped my life.</h6> -->
<!--             <a mdbBtn color="white" mdbWavesEffect>Learn More</a> -->
            <!--            <a mdbBtn color="white" outline="true" mdbWavesEffect>Learn more</a>-->
          </div>
          <!--Grid column-->
          <!--Grid column-->
          <div class="col-md-6 col-xl-5 mt-xl-5 wow fadeInRight" data-wow-delay="0.3s">
            <img src="./assets/juan.png" alt="" class="img-fluid">
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Content -->
    </div>
    <!-- Mask & flexbox options-->
  </div>
  <!-- Full Page Intro -->
</header>
<!-- Main navigation -->
<!-- Section: Contact v.3 -->
<div class="container">
  <!------------------------------  RESUME-->

  <hr>
<!-- <app-resume></app-resume> -->
  <!-----------  CONTACT-->

  <section class="contact-section my-5">

    <!-- Form with header -->
    <div class="card">

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-8">

          <div class="card-body form">
            <!-- Header -->
            <h3 class="mt-4"><mdb-icon fas icon="envelope" class="pr-2"></mdb-icon>Write to me:</h3>
            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <input type="text" id="form-contact-name" class="form-control" mdbInput>
                  <label for="form-contact-name" class="">Your name</label>
                </div>
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <input type="text" id="form-contact-email" class="form-control" mdbInput>
                  <label for="form-contact-email" class="">Your email</label>
                </div>
              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-md-6">
                <div class="md-form mb-0">
                  <input type="text" id="form-contact-phone" class="form-control" mdbInput>
                  <label for="form-contact-phone" class="">Your phone</label>
                </div>
              </div>
              <!-- Grid column -->

            </div>
            <!-- Grid row -->

            <!-- Grid row -->
            <div class="row">

              <!-- Grid column -->
              <div class="col-md-12">
                <div class="md-form mb-0">
                <textarea type="text" id="form-contact-message" class="form-control md-textarea" rows="3"
                          mdbInput></textarea>
                  <label for="form-contact-message">Your message</label>
                  <a mdbBtn floating="true" size="lg" color="blue" mdbWavesEffect>
                    <mdb-icon far icon="paper-plane"></mdb-icon>
                  </a>
                </div>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-4"  [style]="{'background-color': '#103f6d'}">

          <div class="card-body contact text-center h-100 white-text">

            <h3 class="my-4 pb-2">Contact information</h3>
            <ul class="text-lg-left list-unstyled ml-4">
              <li>
                <p><mdb-icon fas icon="map-marked" class="pr-2"></mdb-icon>Illinois, 60402, USA</p>
              </li>
            </ul>
            <hr class="hr-light my-4">
            <ul class="list-inline text-center list-unstyled">
              <li class="list-inline-item">
                <a class="p-2 fa-lg tw-ic">
                  <mdb-icon fab icon="twitter"></mdb-icon>
                </a>
              </li>
              <li class="list-inline-item">
                <a class="p-2 fa-lg li-ic">
                  <mdb-icon fab icon="linkedin-in"> </mdb-icon>
                </a>
              </li>
              <li class="list-inline-item">
                <a class="p-2 fa-lg ins-ic">
                  <mdb-icon fab icon="instagram"> </mdb-icon>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
    <!-- Form with header -->

  </section>
  <!-- Section: Contact v.3 -->
  <a routerLink="/links">.</a>
  <a routerLink="/affiliate-links">..</a>
</div>

