import { Component, OnInit } from '@angular/core';
// @ts-ignore
import * as data from "../../assets/resume-files/resume.json";

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  resume = data.resume;
  skills:string ="";
  constructor() { }

  ngOnInit(): void {
    for(let i = 0; i < this.resume.skills.length; i++){
      if(i != this.resume.skills.length-1){
        this.skills += this.resume.skills[i].name+", ";
      }else{
        this.skills +=" and "+ this.resume.skills[i].name;
      }
    }
  }
  returnPercent(percent): string{
    return percent+'%';
  }
}
