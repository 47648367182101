<a href="../../../assets/files/jquery.js" style="display: none"> Jquery</a>
<a href="../../../assets/files/getListJquery.txt" style="display: none"> Jquery</a>
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark" style="background:#4b515d!important; "
            mdbStickyHeader>
  <mdb-navbar-brand>


    <a class="navbar-brand" href="/">
      <img src="assets/logo.png" style="width:40px; height:40px;">
    </a>
    <a routerLink="/" [style]="{'color':'#fff'}"  ><mdb-icon fas icon="angle-left"></mdb-icon> Back</a>

  </mdb-navbar-brand>
</mdb-navbar>
<div class="container"  style="z-index: 100;padding-top:66px; background:white">
  <h2>Instructions</h2>
  <div class="row" style="background-color: #fad1ff">
    <div >
      <h3>Enter Link</h3>
      <p>https://www.youtube.com/watch?v=pQK4ibhQvmU</p>
      <h3>Replace replaceable substring with {{'{'}}REPLACE{{'}'}} token </h3>
      <p>https://www.youtube.com/watch?v=<b>{{'{'}}REPLACE{{'}'}}</b> </p>
      <h3>Enter List of wanted generated links</h3>
      <p>VSKTmsymPNk</p>
      <p>pQK4ibhQvmU</p>
      <p>fjSTBWR-D1A</p>
      <h3>Result</h3>
      <p><a href="https://www.youtube.com/watch?v=VSKTmsymPNk" target="_blank">https://www.youtube.com/watch?v=VSKTmsymPNk</a></p>
      <p> <a href="https://www.youtube.com/watch?v=pQK4ibhQvmU" target="_blank">https://www.youtube.com/watch?v=pQK4ibhQvmU</a></p>
      <p> <a href="https://www.youtube.com/watch?v=fjSTBWR-D1A" target="_blank">https://www.youtube.com/watch?v=fjSTBWR-D1A</a></p>
    </div>
  </div>
  <h2>Your Turn</h2>
  <div class="row">
    <div >
      <h3>Enter Link with substring token </h3>

      <input placeholder="https://www.yourLinkwithTOKEN.com/{{'{'}}REPLACE{{'}'}}"
             [(ngModel)]="urlDesired"
             style="min-width: 400px;">
      <h3>Enter List of wantred generated links</h3>
      <textarea placeholder="ENTER LIST"
             [(ngModel)]="tokenList"
             style="min-width: 400px; min-height: 300px; height: fit-content"></textarea>
      <h3>Result</h3>
      <div *ngFor="let item of result">
        <a href="{{item}}" target="_blank">  {{item}}</a>
      </div>

      <button (click)="convert()">
        Convert
      </button>

<!--      <input type="text" value="User input Text to copy" #userinput>-->
<!--      <button (click)="copyInputMessage(userinput)" value="click to copy" >Copy from Textbox</button>-->

    </div>

  </div>

<div class="row" style="height: 50px;"></div>
</div>
