<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark" style="background:#4b515d!important; "
            mdbStickyHeader>
  <mdb-navbar-brand>


    <a class="navbar-brand" href="/">
      <img src="assets/logo.png" style="width:40px; height:40px;">
    </a>
    <a routerLink="/" [style]="{'color':'#fff'}"  ><mdb-icon fas icon="angle-left"></mdb-icon> Back</a>

  </mdb-navbar-brand>
</mdb-navbar>

<!--Content BELOW-->
<div class="container" >
  <div mdbSticky style="z-index: 100;padding-top:66px; background:white">
  <button mdbBtn
          type="button"
          color="elegant"
          mdbWavesEffect
          (click)="buttonPress('All')">All</button>
  <button mdbBtn
          type="button"
          style="background:#006d70; color:white;"
          mdbWavesEffect
          (click)="buttonPress('Arduino')">Arduino</button>
  <button mdbBtn
          type="button"
          color="light-blue"
          (click)="buttonPress('VR')"
          mdbWavesEffect>AR & VR</button>
  <button mdbBtn
          type="button"
          color="deep-orange"
          (click)="buttonPress('Website')"
          mdbWavesEffect>Website</button>
  <button mdbBtn
          type="button"
          color="blue-grey"
          (click)="buttonPress('other')"
          mdbWavesEffect>Hobbies & Other</button>
  </div>
<!--  <button mdbBtn type="button" color="blue-grey" mdbWavesEffect>Blue-Grey</button>-->
<!--  <button mdbBtn type="button" color="mdb-color" mdbWavesEffect>MDB</button>-->

<div class="row">
  <div class="col-md-4"  *ngFor="let item of portfolio" [ngStyle]="{'display':itemDisplay(item.type), 'margin-top': '15px'}">
    <!--Card-->
    <mdb-card cascade="true" narrower="true"   >

      <!--Card image-->
      <div class="view view-cascade overlay waves-light" mdbWavesEffect>
        <mdb-card-img src="./assets/portfolio/{{item.img}}"></mdb-card-img>
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>
      <!--/Card image-->

      <mdb-card-body cascade="true" class="text-center pb-0 px-0">
        <!--Card content-->
        <h5  [ngStyle]="{'color':getColor(item.type)}" >Category: {{item.type}}</h5>
        <!--Title-->
        <h4 class="card-title" style="text-transform: capitalize!important;">{{item.name}}</h4>
        <!--Text-->

        <mdb-card-text >
         {{item.description}}
        </mdb-card-text>
        <a mdbBtn
           [ngStyle]="{'background':getColor(item.type), 'color':'#fff'}"
           class="waves-light"
           href="{{item.link}}"
           target="_blank"
           mdbWavesEffect>View</a>
        <!-- Card footer -->
        <div class="card-footer text-muted text-center mt-4">
          {{item.date}}
        </div>
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>


</div>
</div>
