import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-to-url',
  templateUrl: './list-to-url.component.html',
  styleUrls: ['./list-to-url.component.scss']
})

export class ListToURLComponent implements OnInit {

  tokenList: string;
  urlDesired: string= 'https://garage.vn.teslamotors.com/vehicles/{REPLACE}';
  tokenArray = [];
  result = [];
  temp :string;
  constructor() { }
  convert(){
    this.result = [];
    this.tokenArray = this.tokenList.split("\n");
    for(let i = 0; i < this.tokenArray.length; i++){
      this.temp = this.urlDesired;
      if(this.tokenArray[i].length > 0)
      this.result.push(this.temp.replace('{REPLACE}',this.tokenArray[i]))
    }

  }
  ngOnInit(): void {
  }
  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

}
