import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
// @ts-ignore
import * as data from './resume.json';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {
  resume = data.resume;
  skills:string ="";
  constructor() { }

  ngOnInit(): void {
    for(let i = 0; i < this.resume.skills.length; i++){
      if(i != this.resume.skills.length-1){
        this.skills += this.resume.skills[i].name+", ";
      }else{
        this.skills +=" and "+ this.resume.skills[i].name;
      }
    }
  }
  returnPercent(percent): string{
    return percent+'%';
  }
}
