import { Component, OnInit, AfterViewInit } from '@angular/core';
// @ts-ignore
import   *  as data from './portfolio.json';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})

export class PortfolioComponent implements OnInit {

  constructor() { }

  portfolio = data.portfolio;

  ngOnInit(): void {
  }
  portfolioItems = ["block","block","block","block"];

  getColor( itemType) : string{

    switch (itemType){
      case "Arduino":
        return "#006d70";
      case "VR":
        return "#82b1ff";
      case "Website":
        return "#ff7043";
      case "other":
        return "#78909c";
      default:
        return "red";
    }
  }
  itemDisplay( itemType ) : string{
    switch (itemType){
      case "Arduino":
        return this.portfolioItems[0];
      case "VR":
        return this.portfolioItems[1];
      case "Website":
        return this.portfolioItems[2];
      case "other":
        return this.portfolioItems[3];
      default:
        return "display";
    }
  }

  buttonPress( itemType ) : void{
    switch (itemType){
      case "Arduino":
        this.portfolioItems[0] = "block";
        this.portfolioItems[1] = "none";
        this.portfolioItems[2] = "none";
        this.portfolioItems[3] = "none";
        break;
      case "VR":
        this.portfolioItems[0] = "none";
        this.portfolioItems[1] = "block";
        this.portfolioItems[2] = "none";
        this.portfolioItems[3] = "none";
        break;
      case "Website":
        this.portfolioItems[0] = "none";
        this.portfolioItems[1] = "none";
        this.portfolioItems[2] = "block";
        this.portfolioItems[3] = "none";
        break;
      case "other":
        this.portfolioItems[0] = "none";
        this.portfolioItems[1] = "none";
        this.portfolioItems[2] = "none";
        this.portfolioItems[3] = "block";
        break;
      default:
        this.portfolioItems[0] = "block";
        this.portfolioItems[1] = "block";
        this.portfolioItems[2] = "block";
        this.portfolioItems[3] = "block";
    }
  }
}



