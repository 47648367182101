import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
   const webURL = 'https://moraza.one/'
   this.router.parseUrl('https://moraza.one/')
  }

}
