<!-- Main navigation -->
<header>
  <!--Navbar-->
<!--  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar" style=" ">-->
<!--    <form class="form-inline" >-->
<!--      <div class="md-form my-0">-->
<!--        <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">-->
<!--      </div>-->
<!--    </form>-->
<!--  </mdb-navbar>-->
  <!-- Full Page Intro -->
  <div class="view jarallax" style="background-image: url('./assets/background.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <!-- Mask & flexbox options-->
    <div class="mask rgba-black-light d-flex justify-content-center align-items-center">
      <!-- Content -->
      <div class="container">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-md-12 mb-4 white-text text-center">
            <h1 class="h1-reponsive white-text text-uppercase font-weight-bold mb-0 pt-md-5 pt-5 wow fadeInDown"
                data-wow-delay="0.3s"><strong>Juan Moraza</strong></h1>
            <hr class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s">
            <h5 class="text-uppercase mb-4 white-text wow fadeInDown" data-wow-delay="0.4s">
              You miss 100% the shots you don't take<br>
              <strong> - Wayne Gretzky </strong>
            </h5>
            <a mdbBtn color="white"
               outline="true"
               mdbWavesEffect
               class="wow fadeInDown"
               data-wow-delay="0.4s"
               routerLink="/about-me">
            About me</a>
            <a mdbBtn color="white"
               outline="true"
               mdbWavesEffect
               class="wow fadeInDown"
               data-wow-delay="0.4s"
               routerLink="/portfolio">portfolio</a>

            <div class="col-md-12 mb-4">
               <a mdbBtn color="white"
                 outline="true"
                 mdbWavesEffect
                 class="wow fadeInDown"
                 data-wow-delay="0.4s"
                 routerLink="/blog">Blog</a>
              
              <a mdbBtn color="white"
                 outline="true"
                 mdbWavesEffect
                 class="wow fadeInDown"
                 data-wow-delay="0.4s"
                 routerLink="/links">Links</a>
          </div>
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Content -->
    </div>
    <!-- Mask & flexbox options-->
  </div>

  <!-- Full Page Intro -->
</header>
